@import 'reset';

body {
  background-color: #2D2D2D;
  color: #fff;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

main {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  min-height: 100%;
  cursor: pointer;
  height: 100%;
}

footer {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  min-height: 100%;
  cursor: pointer;
  height: 100%;

  > * {
    padding: 50px;
  }
}

.portfolio {
  &__about {
    padding: 50px;
    max-width: 70ch;
  }

  &__portrait {
    border-radius: 50%;
    margin-bottom: 3rem;
    width: auto;
    height: 200px;
  }
}

h1 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}