html {
  box-sizing: border-box;
  font-size: 18px;
}

*, :before, :after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  color: #fff;
  background-color: #2d2d2d;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

main, footer {
  cursor: pointer;
  justify-content: left;
  align-items: center;
  height: 100%;
  min-height: 100%;
  display: flex;
  position: relative;
}

footer > * {
  padding: 50px;
}

.portfolio__about {
  max-width: 70ch;
  padding: 50px;
}

.portfolio__portrait {
  border-radius: 50%;
  width: auto;
  height: 200px;
  margin-bottom: 3rem;
}

h1 {
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: bold;
}

p {
  margin-bottom: 1rem;
}

/*# sourceMappingURL=index.2d22c3d0.css.map */
